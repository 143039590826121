<template>
  <div class="card card-custom">
    <div class="full-load" v-if="generateInvoice">
      <h1 v-html="loadingMessage"></h1>
    </div>
    <div class="card-body p-0">
      <!--begin::Invoice-->
      <button
        class="btn btn-success mt-5 mr-5 btn-back"
        id="btn-back"
        style="float:right"
        @click="goBack"
      >
        Go Back
      </button>
      <div
        class="row justify-content-center pt-8 px-8 pt-md-27 px-md-0"
        id="printMe"
      >
        <div class="col-md-9" v-if="invoiceDetails != null">
          <!-- begin: Invoice header-->
          <div
            class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row"
          >
            <h1 class="display-4 font-weight-boldest mb-10">INVOICE</h1>

            <div class="d-flex flex-column align-items-md-end px-0">
              <span
                class="d-flex flex-column align-items-md-end  font-weight-bold text-muted"
              >
                <span>{{ appDetails.sp_name }}, </span>
                <span> {{ appDetails.sp_address }}</span>
                <!-- <span>Mississippi 96522</span> -->
              </span>
            </div>
          </div>

          <!--end: Invoice header-->
          <!--begin: Invoice body-->
          <div class="row border-bottom pb-10">
            <div class="col-md-9 py-md-10 pr-md-10">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="pt-1 pb-9 font-weight-bolder text-muted font-size-lg text-uppercase"
                      >
                        Description
                      </th>
                      <th
                        class="pt-1 pb-9 text-right font-weight-bolder text-muted font-size-lg text-uppercase"
                      >
                        Qty
                      </th>

                      <th
                        class="pt-1 pb-9 text-right pr-0 font-weight-bolder text-muted font-size-lg text-uppercase"
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="font-weight-bolder border-bottom-0 font-size-lg"
                      v-for="(item, index) in invoiceDetails"
                      :key="index"
                    >
                      <td>
                        {{ item.service_name }}
                      </td>
                      <td class="text-right">
                        {{ item.quantity }}
                      </td>
                      <td class="text-right">${{ item.total }}</td>
                    </tr>

                    <tr class="font-weight-bolder border-bottom-0 font-size-lg">
                      <td>Total</td>
                      <td class="text-right">
                        {{ appDetails.total_quantity }}
                      </td>
                      <td class="text-right">${{ appDetails.grand_total }}</td>
                    </tr>
                  </tbody>
                </table>
                <hr />
              </div>
            </div>

            <div
              class="col-md-3 border-left-md pl-md-10 py-md-10 text-right move-up-side-info"
            >
              <!--begin::Total Amount-->
              <div class="font-size-h4 font-weight-bolder text-muted mb-3">
                TOTAL AMOUNT
              </div>
              <div class="font-size-h1 font-weight-boldest">
                ${{ appDetails.grand_total | toFixedTwoDigit }}
              </div>
              <div class="text-muted font-weight-bold mb-16">
                Taxes included
              </div>
              <!--end::Total Amount-->
              <div class="border-bottom w-100 mb-16"></div>

              <!--begin::Invoice To-->
              <div class="text-dark-50 font-size-lg font-weight-bold mb-3">
                INVOICE TO.
              </div>
              <div class="font-size-lg font-weight-bold mb-10">
                {{ appDetails.company_name }}
              </div>
              <!--end::Invoice No-->
              <!--begin::Invoice Date-->
              <div class="text-dark-50 font-size-lg font-weight-bold mb-3">
                DATE
              </div>
              <div class="font-size-lg font-weight-bold">
                {{ appDetails.created_at | dateFormat }}
              </div>
              <!-- <div class="text-dark-50 font-size-lg font-weight-bold mb-3">
                Updated Date
              </div>
              <div class="font-size-lg font-weight-bold">
                {{ invoiceDetails.updated_at | dateFormatTime }}
              </div> -->
              <!--end::Invoice Date-->
            </div>
            <!--end: Invoice body-->
          </div>
        </div>
      </div>
      <!-- begin: Invoice action-->
      <div
        class="row justify-content-center py-8 px-8 py-md-28 px-md-0"
        id="printpagebutton"
      >
        <div class="col-md-9">
          <div class="d-flex font-size-sm flex-wrap">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder py-4 mr-3 mr-sm-14 my-1"
              @click="printInvoice()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <!-- end: Invoice action-->
      <!--end::Invoice-->
    </div>
  </div>
</template>

<script>
import {
  FETCH_COMPANY_INVOICE_DETAILS,
  CREATE_COMPANY_INVOICE
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import moment from "moment";
export default {
  filters: {
    dateFormat(date) {
      return moment(date).format("DD MMM, YYYY");
    },
    dateFormatTime(date) {
      return moment(date).format("DD MMM,  HH:mm:ss");
    },
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    }
  },
  data() {
    return {
      invoiceDetails: null,
      generateInvoice: false,
      loadingMessage: "",
      appDetails: null,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Invoice Details",
          route: ""
        }
      ]
    };
  },
  mounted() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getInvoiceDetails();
  },

  methods: {
    //   printMe
    printInvoice() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to create invoice",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        everseButtons: true
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.generateInvoice = true;
          this.loadingMessage = "Saving Invoice ...";
          var appointments = window.localStorage.getItem(
            "company_invoice_appointment"
          );
          var data_ = {
            appointments: appointments,
            company: this.$route.params.company_id
          };
          this.$store.dispatch(CREATE_COMPANY_INVOICE, data_).then(data => {
            const contentDisposition = data.headers["content-disposition"];
            let fileName = "unknown";
            if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
              if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
            }
            const url = window.URL.createObjectURL(
              new Blob([data.data], { type: "application/pdf" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          });
          this.generateInvoice = false;
          this.loadingMessage = "";
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    getInvoiceDetails() {
      var appointments = window.localStorage.getItem(
        "company_invoice_appointment"
      );
      var data_ = {
        appointments: appointments,
        company: this.$route.params.company_id
      };
      this.$store.dispatch(FETCH_COMPANY_INVOICE_DETAILS, data_).then(res => {
        this.invoiceDetails = res.data;
        this.appDetails = res;
      });
    }
  }
};
</script>
